import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class JsonApiInterceptor implements HttpInterceptor {
  constructor(private translocoService: TranslocoService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('http') > -1) {
      const urlObject: URL = new URL(request.url);
      const { origin, pathname, search } = urlObject;
      const i18nUrl = `${origin}/${this.translocoService.getActiveLang()}${pathname}${search}`;
      if (pathname?.indexOf('/router/translate-path') > -1) {
        const i18nRequest = request.clone({ url: i18nUrl });
        return next.handle(i18nRequest);
      }
      return next.handle(request);
    }
    return next.handle(request);
  }
}
