import { isPlatformBrowser } from '@angular/common';
import { ApplicationRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isZonePresent } from './is-zone-present';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';

/**
 * A small service encapsulating the hacks needed for routing (and bootstrapping) in zone-less applications
 */
@Injectable({
  providedIn: 'root',
  deps: [ComponentStore],
})
export class ZonelessRouting extends ComponentStore<Record<string, never>> {
  constructor(
    private router: Router,
    private appRef: ApplicationRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WINDOW) private window: Window
  ) {
    super();
  }

  getNavigationEnd = this.effect((routerEvents$: Observable<any>) => {
    return routerEvents$.pipe(
      tap((e) => {
        if (e instanceof NavigationEnd) {
          this.window.scrollTo({ top: 0, behavior: 'smooth' });
          if (this.window.top) {
            this.window.top.postMessage(e.url, '*');
          }
          this.appRef.tick();
        }
      })
    );
  });

  init() {
    /**
     * **🚀 Perf Tip:**
     *
     * In zone-less applications we have to trigger CD on every `NavigationEnd` event that changes the view.
     * This is a necessity to make it work zone-less, but does not make the app faster.
     */
    if (isPlatformBrowser(this.platformId) && !isZonePresent()) {
      this.getNavigationEnd(this.router.events);
    }
  }
}
