import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterState } from '@ngrx/router-store';

export interface State {
  router: RouterState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
};
