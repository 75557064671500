import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SwBypassInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.params.has('ngsw-bypass')) {
      return next.handle(
        req.clone({ params: req.params.append('ngsw-bypass', '1') })
      );
    }
    return next.handle(req);
  }
}
