import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DrupalMenuItem,
  DrupalTerm,
  TranslationItem,
  Tree,
} from '@madeinlune/drupal-types';
import { SeoEntity } from '@madeinlune/massaud-types';

export const BASE_URL = new InjectionToken<string>('BASE_URL');

export const TYPOLOGIE_VOCABULARY = new InjectionToken<
  Observable<DrupalTerm[]>
>('TYPOLOGIE_VOCABULARY');

export const MAIN_MENU_LINKS = new InjectionToken<Observable<DrupalMenuItem[]>>(
  'MAIN_MENU_LINKS'
);

export const MAIN_MENU = new InjectionToken<Observable<Tree<any>>>('MAIN_MENU');
export const HEADER_MENU = new InjectionToken<Observable<Tree<any>>>(
  'HEADER_MENU'
);

export const TYPOLOGIES_MENU = new InjectionToken<Observable<Tree<any>>>(
  'TYPOLOGIES_MENU'
);

export const ROUTED_ENTITY = new InjectionToken<Observable<SeoEntity | null>>(
  'ROUTED_ENTITY'
);

export const ROUTED_ENTITY_ALTERNATIVES = new InjectionToken<
  Observable<TranslationItem[]>
>('ROUTED_ENTITY_ALTERNATIVES');
