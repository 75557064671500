import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { BASE_URL } from '@nx-massaud/shell/feature/tokens';
import { NgrxDrupalPathsToRoutesModule } from '@madeinlune/ngrx-drupal-paths-to-routes';
import { MassaudDrupalToAngularPipe } from '@nx-massaud/routing/path-to-route';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { reducers } from './reducers';
import { TranslocoRootModule } from './transloco-root.module';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { LOCATION } from '@ng-web-apis/common';
import { Observable, of, tap } from 'rxjs';
import { JsonApiInterceptor } from './json-api.interceptor';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { PwaManifestModule } from '@madeinlune/ngx-pwa-manifest';
import { DialogModule } from '@angular/cdk/dialog';
import { SwBypassInterceptor } from './sw-bypass-interceptor';
import {
  NgxPiwikProModule,
  NgxPiwikProRouterModule,
} from '@piwikpro/ngx-piwik-pro';

registerLocaleData(localeFr, 'fr');

function initializeTransloco(
  translocoService: TranslocoService,
  location: Location
): () => Observable<any> {
  return () =>
    of(null).pipe(
      tap(() => {
        const browserLang: string | undefined = getBrowserLang();
        const urlLanguage: string | undefined =
          location.pathname.split('/')?.[1];
        if ((!urlLanguage && browserLang === 'fr') || urlLanguage === 'fr') {
          translocoService.setActiveLang('fr');
        } else {
          translocoService.setActiveLang('en');
        }
      })
    );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ...(environment.production
      ? [
          NgxPiwikProModule.forRoot(
            '74dfab82-68fe-4e9f-a4d7-ac75cce456d7',
            'https://icilalune.piwik.pro'
          ),
          NgxPiwikProRouterModule,
        ]
      : []),
    PwaManifestModule.forRoot(),
    DialogModule,
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => {
            return import('@nx-massaud/shell/feature/routes').then(
              (mod) => mod.ROUTES
            );
          },
        },
      ],
      {
        initialNavigation: 'enabledBlocking',
      }
    ),
    StoreModule.forRoot(reducers, {
      metaReducers: environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    StoreRouterConnectingModule.forRoot(),
    HttpClientModule,
    NgrxDrupalPathsToRoutesModule.forRoot(
      ['en', 'fr'],
      `${environment.config.baseUrl}/router/translate-path`,
      MassaudDrupalToAngularPipe,
      true
    ),
    TranslocoRootModule,
    ScullyLibModule.forRoot({ useTransferState: true }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService, LOCATION],
      useFactory: initializeTransloco,
    },
    {
      provide: BASE_URL,
      useValue: environment.config.baseUrl,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SwBypassInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
