import { Pipe, PipeTransform } from '@angular/core';
import { AppPathInfos } from '@madeinlune/drupal-types';

@Pipe({
  name: 'massaudDrupalToAngular',
  standalone: true,
})
export class MassaudDrupalToAngularPipe implements PipeTransform {
  partnersPageId = '7a9bceda-6e45-4d21-beab-406fbe6c0a2d';
  transform(pathInfos: AppPathInfos): string | undefined {
    const dataType: string = pathInfos.type;
    let angularPath: string | undefined = `/${pathInfos.language}`;
    if (dataType === 'taxonomy_term--typologies') {
      angularPath = angularPath + `/typology/${pathInfos.id}`;
    } else if (dataType === 'taxonomy_term--press_years') {
      angularPath = angularPath + `/press/${pathInfos.id}`;
    } else if (dataType === 'node--project') {
      angularPath = angularPath + `/project/${pathInfos.id}`;
    } else if (dataType === 'node--partner') {
      angularPath = `${angularPath}/partners/${this.partnersPageId}/${pathInfos.id}`;
    } else if (dataType === 'node--article') {
      angularPath = angularPath + `/article/${pathInfos.id}`;
    } else if (dataType === 'node--home') {
      angularPath = angularPath + `/home/${pathInfos.id}`;
    } else if (dataType === 'node--page') {
      if (
        pathInfos.path.indexOf('partenaires') > -1 ||
        pathInfos.path.indexOf('partners') > -1
      ) {
        angularPath = `${angularPath}/partners/${pathInfos.id}`;
      } else if (
        pathInfos.path.indexOf('manifeste') > -1 ||
        pathInfos.path.indexOf('manifest') > -1 ||
        pathInfos.path.indexOf('bio') > -1 ||
        pathInfos.path.indexOf('contact') > -1
      ) {
        angularPath = angularPath + `/page/${pathInfos.id}`;
      } else if (pathInfos.path.indexOf('news') > -1) {
        angularPath = angularPath + `/news-list/${pathInfos.id}`;
      } else if (
        pathInfos.path.indexOf('mentions-legales') > -1 ||
        pathInfos.path.indexOf('terms-and-conditions') > -1 ||
        pathInfos.path.indexOf('politique-de-confidentialite') > -1
      ) {
        angularPath = angularPath + `/legal/${pathInfos.id}`;
      } else if (
        pathInfos.path.indexOf('dossiers-de-presse') > -1 ||
        pathInfos.path.indexOf('parutions') > -1
      ) {
        angularPath = angularPath + `/press/${pathInfos.id}`;
      } else {
        angularPath = undefined;
      }
    } else {
      angularPath = undefined;
    }
    return angularPath;
  }
}
