import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      ngZone: 'noop',
      defaultEncapsulation: ViewEncapsulation.None,
    })
    .then((ref) => {
      // Ensure Angular destroys itself on hot reloads.
      // @ts-ignore
      const ngRef = window['ngRef'];
      if (ngRef) {
        ngRef.destroy();
      }
      // @ts-ignore
      window['ngRef'] = ref;
    })
    .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
