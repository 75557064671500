import {
  EventEmitter,
  Inject,
  Injectable,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import { NAVIGATOR, WINDOW } from '@ng-web-apis/common';
import {
  combineLatest,
  delay,
  filter,
  from,
  fromEvent,
  map,
  Observable,
  of,
  ReplaySubject,
  startWith,
  take,
  tap,
} from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ComponentStore } from '@ngrx/component-store';
import { Platform } from '@angular/cdk/platform';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { PwaManifestService } from '@madeinlune/ngx-pwa-manifest';

@NgModule({
  imports: [CommonModule],
})
export class PwaModule {}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

interface PwaServiceState {
  serviceWorkerInstalled: boolean;
  installed: boolean;
  canInstall: boolean;
  beforeinstallprompt?: BeforeInstallPromptEvent;
  showInstallPrompt: boolean;
}

@Injectable()
export class PwaService extends ComponentStore<PwaServiceState> {
  readonly installed$ = this.select((s) => s.installed);
  readonly canInstall$ = this.select((s) => s.canInstall);
  readonly showInstallPrompt$ = this.select((s) => s.showInstallPrompt);
  readonly serviceWorkerInstalled$ = this.select(
    (s) => s.serviceWorkerInstalled
  );

  readonly installLater$: ReplaySubject<boolean> = new ReplaySubject<boolean>(
    1
  );

  serviceWorkerRegistration: ServiceWorkerRegistration | undefined;
  installLater!: boolean;
  #deferredPwaPromptEvent!: BeforeInstallPromptEvent | null;

  readonly isIOS$ = of(this.platform.IOS);

  setInstalled = this.effect((installed$: Observable<boolean>) => {
    return installed$.pipe(
      filter((installed) => installed),
      take(1),
      tap((installed) => {
        if (installed) {
          if (!this.platform.IOS && !this.platform.ANDROID) {
            const windowWidth = Math.min(this.window.screen.availWidth, 1600);
            const windowHeight = Math.min(this.window.screen.availHeight, 900);
            this.window.resizeTo(windowWidth, windowHeight);
          }
        }
      })
    );
  });

  setCanInstall = this.effect((canInstall$: Observable<boolean>) => {
    return canInstall$.pipe(
      tap((canInstall) => {
        if (canInstall) {
          this.patchState({ showInstallPrompt: true });
        }
      })
    );
  });

  setServiceWorkerInstalled = this.effect(
    (serviceWorkerInstalled$: Observable<boolean>) => {
      return serviceWorkerInstalled$.pipe(
        filter((serviceWorkerInstalled) => !!serviceWorkerInstalled),
        delay(2000),
        tap((serviceWorkerInstalled) => {
          if (serviceWorkerInstalled) {
            if (this.platform.IOS) {
              const installed = this.get((s) => s.installed);
              if (!installed) {
                this.patchState({ canInstall: true });
              }
            }
          }
        })
      );
    }
  );

  checkMediaQueries = this.effect(
    (
      mediaQueries$: Observable<[boolean, boolean, boolean, boolean, boolean]>
    ) => {
      return mediaQueries$.pipe(
        tap(([iosInstalled, twa, standalone, fullscreen, minimalUi]) => {
          const isInstalled =
            iosInstalled || twa || standalone || fullscreen || minimalUi;
          this.patchState({ installed: isInstalled });
        })
      );
    }
  );

  readonly updateEmitter: EventEmitter<any> = new EventEmitter<any>();

  readonly iconUrl$: Observable<string | undefined> =
    this.pwaManifestService.icons$.pipe(
      map((icons) => {
        return icons.find((icon) => icon.sizes === '192x192')?.src;
      })
    );

  constructor(
    private swUpdate: SwUpdate,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    @Inject(NAVIGATOR) private navigator: Navigator,
    @Inject(PLATFORM_ID) private platformId: string,
    public platform: Platform,
    public scrollDispatcher: ScrollDispatcher,
    private pwaManifestService: PwaManifestService
  ) {
    super({
      installed: false,
      canInstall: false,
      showInstallPrompt: false,
      serviceWorkerInstalled: false,
    });
    if (isPlatformBrowser(this.platformId) && this.navigator.serviceWorker) {
      this.setInstalled(this.installed$);
      this.setServiceWorkerInstalled(this.serviceWorkerInstalled$);

      from(this.navigator.serviceWorker.getRegistration()).subscribe({
        next: (result) => {
          console.log('PwaService', { result });
          this.serviceWorkerRegistration = result;
          this.patchState({ serviceWorkerInstalled: true });
        },
        error: (error) => {
          console.error('PwaService', { error });
        },
      });
      const isIosInstalled$ = of(
        (this.window.navigator as any)?.['standalone'] === true
      );
      const isTwa$ = of(document.referrer.includes('android-app://'));
      const standaloneMediaQuery$ = fromEvent<MediaQueryList>(
        this.standaloneMediaQuery,
        'change'
      ).pipe(
        startWith(this.standaloneMediaQuery),
        map((list: MediaQueryList) => list.matches)
      );
      const fullscreenMediaQuery$ = fromEvent<MediaQueryList>(
        this.fullscreenMediaQuery,
        'change'
      ).pipe(
        startWith(this.fullscreenMediaQuery),
        map((list: MediaQueryList) => list.matches)
      );
      const minimalUiMediaQuery$ = fromEvent<MediaQueryList>(
        this.minimalUiMediaQuery,
        'change'
      ).pipe(
        startWith(this.minimalUiMediaQuery),
        map((list: MediaQueryList) => list.matches)
      );

      this.checkMediaQueries(
        combineLatest([
          isIosInstalled$,
          isTwa$,
          standaloneMediaQuery$,
          fullscreenMediaQuery$,
          minimalUiMediaQuery$,
        ])
      );
    }

    this.setCanInstall(this.canInstall$);

    swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`VERSION_READY`);
          this.updateEmitter.emit();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });

    /*    swUpdate.unrecoverable.subscribe((unrecoverable) => {
      console.log('swUpdate.unrecoverable', { unrecoverable });
    });*/

    this.patchState(
      fromEvent(this.window, 'beforeinstallprompt').pipe(
        map((event) => {
          return {
            canInstall: true,
            beforeinstallprompt: event as BeforeInstallPromptEvent,
          };
        })
      )
    );

    /*this.showInstallPrompt$.subscribe((showInstallPrompt) =>
      console.log({ showInstallPrompt })
    );*/

    /*setTimeout(() => {
      this.#openInstallWindow();
    }, 4000);*/

    /*setTimeout(() => {
      this.#openUpdateWindow();
    }, 4000);*/

    /*setTimeout(() => {
      this.patchState({ canInstall: true });
    }, 2000);*/

    /*setTimeout(() => {
      this.patchState({ showInstallPrompt: true });
    }, 2000);*/

    /*setTimeout(() => {
      this.updateEmitter.emit();
    }, 2000);*/

    /*this.canInstall$.subscribe((canInstall) => {
      console.log({ canInstall });
    });*/
  }

  checkInstalled() {}

  async install() {
    const beforeinstallpromptEvent = this.get((s) => s.beforeinstallprompt);
    if (beforeinstallpromptEvent) {
      // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
      await beforeinstallpromptEvent.prompt();
      // Find out whether the user confirmed the installation or not
      const { outcome } = await beforeinstallpromptEvent.userChoice;
      // The deferredPrompt can only be used once.
      this.patchState({ beforeinstallprompt: undefined });
      // Act on the user's choice
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt.');
      } else if (outcome === 'dismissed') {
        console.log('User dismissed the install prompt');
      }
    }
  }

  unInstall() {
    if (this.serviceWorkerRegistration) {
      from(this.serviceWorkerRegistration.unregister()).subscribe({
        next: (result) => {
          this.serviceWorkerRegistration = undefined;
        },
        error: (error) => {
          console.error({ error });
        },
      });
    }
  }

  get standaloneMediaQuery(): MediaQueryList {
    return this.window.matchMedia('(display-mode: standalone)');
  }

  get fullscreenMediaQuery(): MediaQueryList {
    return this.window.matchMedia('(display-mode: fullscreen)');
  }

  get minimalUiMediaQuery(): MediaQueryList {
    return this.window.matchMedia('(display-mode: minimal-ui)');
  }
}
