import { ApplicationRef, Component, inject, ViewEncapsulation } from "@angular/core";
import { ZonelessRouting } from './zone-less/zone-less-routing.service';
import { PwaService } from '@nx-massaud/pwa';
import { EMPTY, from, Observable, switchMap, tap } from 'rxjs';
import { ComponentStore } from '@ngrx/component-store';
import {
  Overlay,
  PositionStrategy,
  ScrollStrategy,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { Dialog } from '@angular/cdk/dialog';

const updatePrompt$ = from(import('@nx-massaud/pwa/update-prompt'));

@Component({
  selector: 'msd-root',
  template: ` <router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PwaService],
})
export class AppComponent extends ComponentStore<any> {
  pwaService = inject(PwaService);
  dialog = inject(Dialog);
  sso = inject(ScrollStrategyOptions);
  overlay = inject(Overlay);
  zonelessRouting = inject(ZonelessRouting);
  applicationRef = inject(ApplicationRef);

  openPwaUpdateWindow = this.effect((showUpdate$: Observable<any>) => {
    return showUpdate$.pipe(
      switchMap((showUpdate) => {
        return updatePrompt$.pipe(
          tap((m) => {
            this.openPwaWindow(m.UpdatePromptComponent);
          })
        );
        return EMPTY;
      })
    );
  });

  constructor() {
    super();
    this.zonelessRouting.init();
    this.openPwaUpdateWindow(this.pwaService.updateEmitter.asObservable());
  }

  openPwaWindow(component: any) {
    const scrollStrategy: ScrollStrategy = this.sso.noop();
    const positionStrategy: PositionStrategy = this.overlay
      .position()
      .global()
      .centerVertically()
      .centerHorizontally()
    const dialogRef = this.dialog.open<'openInfos' | undefined>(component, {
      id: 'pwaInstall',
      width: 'clamp(320px, 420px, 90vw)',
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'pwa-alert',
      positionStrategy,
      scrollStrategy
    });
    setTimeout(() => {
      this.applicationRef.tick();
    });
  }
}
